// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
global.toastr = require('toastr');
// import "core-js/stable";
// import "regenerator-runtime/runtime";
import 'bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import '../stylesheets/application';
import 'react-toastify/dist/ReactToastify.css';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../../assets/images', true)
// const imagePath = (name) => images(name, true)

import 'controllers';

import './react';
import './react_on_rails';

$(document).on('turbolinks:load', function () {
  $(document).on('click', '.controled-focus', function (e) {
    e.stopPropagation();
  });
});
