import React, { lazy, Suspense, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App/assets/css/icons.css';

const ClientDocuments = lazy(() => import('./App/routes/client-documents'));
const ClientDocumentsHistory = lazy(() =>
  import('./App/routes/client-documents-history')
);
const ClientFeed = lazy(() => import('./App/routes/client-feed'));
const ClientForms = lazy(() => import('./App/routes/client-forms'));
const ClientFormDetail = lazy(() =>
  import('./App/routes/client-forms/details')
);
const ClientFormSubmissions = lazy(() =>
  import('./App/routes/client-forms/submissions')
);
const ClientTasks = lazy(() => import('./App/routes/client-tasks'));
const ClientTasksNew = lazy(() => import('./App/routes/client-tasks/new'));
const ClientTasksEdit = lazy(() => import('./App/routes/client-tasks/edit'));
const ClientTrash = lazy(() => import('./App/routes/client-trash'));
const ClientSettings = lazy(() => import('./App/routes/client-settings'));
const ClientTrashDocuments = lazy(() =>
  import('./App/routes/client-trash/documents')
);
const ClientTrashUsers = lazy(() => import('./App/routes/client-trash/users'));
const Documents = lazy(() => import('./App/routes/documents'));
const EditClientForm = lazy(() => import('./App/routes/client-forms/edit'));
const EditForm = lazy(() => import('./App/routes/forms/edit'));
const Forms = lazy(() => import('./App/routes/forms'));
const FormPreview = lazy(() => import('./App/routes/forms/preview'));
const FormDetail = lazy(() => import('./App/routes/forms/details'));
const NewForm = lazy(() => import('./App/components/html-form-viewer/new'));
const FormSubmissionView = lazy(() =>
  import('./App/routes/client-forms/submission')
);
const EditSubmission = lazy(() =>
  import('./App/components/html-form-viewer/edit')
);
const ClientNotificationSettings = lazy(() =>
  import('./App/components/client-settings')
);

const TemplateDocumentView = lazy(() =>
  import('./App/routes/template-document-view')
);
const DocSubmissionView = lazy(() =>
  import('./App/routes/doc-submission-view')
);
const ClientDocumentView = lazy(() =>
  import('./App/routes/client-document-view')
);
const DocumentAssignmentView = lazy(() =>
  import('./App/routes/document-assignment-view')
);

const EditClientDocuments = lazy(() =>
  import('./App/routes/client-document-edit')
);

const Spinner = () => (
  <div className='loading-container'>
    <span className='spinner-border' role='status' aria-hidden='true'></span>
  </div>
);

const App = () => {
  return (
    <>
      <ToastContainer position='bottom-right' />
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path='/f/documents' element={<Documents />} />
            <Route
              path='/f/documents/:documentId'
              element={<TemplateDocumentView />}
            />
            <Route
              path='/f/doc_submissions/:docSubmissionId'
              element={<DocSubmissionView />}
            />
            <Route
              path='/f/clients/:clientId/documents'
              element={<ClientDocuments />}
            />
            <Route
              path='/f/clients/:clientId/documents/:documentId'
              element={<ClientDocumentView />}
            />
            <Route
              path='/f/document_assignments/:assignmentId'
              element={<DocumentAssignmentView />}
            />
            <Route
              path='/f/clients/:clientId/documents/edit'
              element={<EditClientDocuments />}
            />
            <Route
              path='/f/clients/:clientId/documents/:id/history'
              element={<ClientDocumentsHistory />}
            />
            <Route path='/f/templates' element={<Forms />} />
            <Route path='/f/templates/:id' element={<FormPreview />} />
            <Route path='/f/templates/:id/details' element={<FormDetail />} />
            <Route path='/f/templates/:id/edit' element={<EditForm />} />
            <Route
              path='/f/clients/:clientId/templates'
              element={<ClientForms />}
            />
            <Route
              path='/f/clients/:clientId/templates/:id/details'
              element={<ClientFormDetail />}
            />
            <Route
              path='/f/clients/:clientId/templates/:templateId/submissions'
              element={<ClientFormSubmissions />}
            />
            <Route
              path='/f/clients/:clientId/templates/:id/edit'
              element={<EditClientForm />}
            />
            {/* <Route path='/f/templates/editor' element={<FormEditor />} /> */}
            <Route
              path='/f/company_documents/:company_document_id/submissions/new'
              element={<NewForm />}
            />
            <Route
              path='/f/company_documents/:company_document_id/submissions/:submission_id/edit'
              element={<EditSubmission />}
            />
            <Route
              path='/f/company_documents/:company_document_id/submissions/:submission_id/show'
              element={<FormSubmissionView />}
            />
            <Route
              path='/f/clients/:clientId/tasks'
              element={<ClientTasks />}
            />
            <Route
              path='/f/clients/:clientId/tasks/new'
              element={<ClientTasksNew />}
            />
            <Route
              path='/f/clients/:clientId/tasks/:taskId/edit'
              element={<ClientTasksEdit />}
            />
            <Route path='/f/clients/:clientId/feed' element={<ClientFeed />} />
            <Route
              path='/f/clients/:clientId/settings'
              element={<ClientSettings />}
            >
              <Route
                path='/f/clients/:clientId/settings/notifications'
                element={<ClientNotificationSettings />}
              />
            </Route>
            <Route path='/f/clients/:clientId/trash' element={<ClientTrash />}>
              <Route
                path='/f/clients/:clientId/trash/documents'
                element={<ClientTrashDocuments />}
              />
              <Route
                path='/f/clients/:clientId/trash/users'
                element={<ClientTrashUsers />}
              />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
